import React, { useEffect } from "react";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Footer";
import GetCounsellingForm from "../components/Common/GetCounsellingForm";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { schemaMarkup } from "../../config";

const BookSession = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);


  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href='https://www.jeantherapyonline.co.uk/get-counselling' />
      </Helmet>
      <div className="overflow">
        <div className="page-container">
          <Navbar />

          <div className="book-now d-md-flex justify-content-around align-items-center" >
            <h1 className="mb-0">Book a Session</h1>
          </div>

          <div className="customer-form position-relative mt-5 up-form" id="booknowform">
            <GetCounsellingForm btnText={"Book Now"}  />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default BookSession;
